<template>
    <HideWhileSpinnerActive>
        <div class="max-w-xl mx-auto pb-10">
            <TinyNavigation :prev="{name: 'reviews'}" class="fixed pt-6 px-6 z-10" />
            <template v-if="!review_not_found">
                <div v-if="hasShield">
                    <div class="w-full relative">
                        <img class="max-w-md mx-auto" src="@/assets/icons/scoreLit_emblem.svg">
                        <div class="absolute pb-5 inset-0 flex flex-col items-center justify-center -mt-24">
                            <h2 class="text-5xl font-bold pb-5 text-scorelitorange">{{ average_score }}</h2>
                            <h6 class="text-2lg font-bold text-white">{{ (review.trainee ?? {}).first_name }}</h6>
                            <h4 class="text-3xl font-bold text-white">{{ (review.trainee ?? {}).last_name }}</h4>
                        </div>
                        <div class="absolute pb-5 inset-0 flex flex-col items-center justify-center mt-56">
                            <div class="mt-10 mx-auto text-center max-w-xs flex flex-wrap px-20">
                                <p v-for="score in filterdScores" :key="score.name" class="w-1/2 mb-1 text-sm text-white"><span class="font-bold mr-2">{{ score.value }}</span>{{ score.name }}</p>
                            </div>
                        </div>
                        <div class="absolute inset-x-0 bottom-0 pb-16">
                            
                        </div>
                    </div>

                    <hr class="my-4 -mx-4 border-gray-800">

                </div>

                <template v-if="hasVideo">
                    <router-link :to="{name: 'review.show.video', params: {id}}">
                        <div class="mx-auto max-w-sm py-10">
                            <div class="relative">
                                <img :src="video_thumbnail" alt="" class="rounded-lg min-h-64">
                                <div class="absolute inset-x-0 bottom-0 h-64 bg-gradient-to-b from-transparent to-black "></div> 
                                <div class="absolute inset-x-0 -mt-24 text-center text-white">
                                    <img class="w-8 h-8 mx-auto mb-6" src="@/assets/icons/play-circle-regular.svg">
                                    <span class="font-bold text-sm">{{ t('watch_video') }}</span>
                                </div> 
                            </div>
                        </div>
                    </router-link>
                </template>

                <div v-if="hasReport">
                    <hr class="my-4 -mx-4 hr-strategy">

                    <h5 class="text-white font-bold text-sm text-center pt-4 pb-6">{{ t('your_review') }}</h5>

                    <div class="border border-gray-800 rounded-lg overflow-hidden">
                        <div v-for="(link, index) in review_links" :key="index" class="border-t border-gray-800 first:border-t-0 hover:bg-scorelitorange transition duration-200 group">
                            <router-link :to="link.to" class="text-gray-100 group-hover:text-white">
                                <div class="w-full p-4 ">
                                    {{ link.text }}
                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-right" class="text-gray-300 group-hover:text-white w-4 h-4 my-1 float-right group-hover:text-white" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                        <path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path>
                                    </svg>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <p class="text-white px-12 pt-10">{{ t('review_not_found') }}</p>
            </template>
        </div>
    </HideWhileSpinnerActive>
</template>

<script>
import TinyNavigation from "@/components/TinyNavigation";
import HideWhileSpinnerActive from "@/wrappers/HideWhileSpinnerActive";
import { getReview } from "@/helpers/api/ReviewHelper";
import messages from "@/helpers/storage/MessageHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";
import { isGeneralsubject } from "@/helpers/storage/ReviewHelper"

export default {
    name: "ReviewShow",
    setup() {
        return useI18n({
            viewPrefix: "ReviewShow"
        });
    },
    components: { TinyNavigation, HideWhileSpinnerActive },
    props: ['id'],
    created(){
        startSpinner()
        getReview(this.id)
            .then((data) => {
                // console.log(data);
                this.review = data;
            })
            .catch(err => {
                if (err.status === 404 || err.status === 403){
                    this.review_not_found = true;
                } else {
                    messages.error(this.t('messages.something_went_wrong', {error: err.message}));
                }
            })
            .finally(()=> stopSpinner());
    },
    data(){
        return {
            review_not_found: false,
            review: [],
        }
    },
    computed: {
        hasShield: function () {
            return this.review?.spec;
        },
        hasReport: function () {
            return this.review?.product?.report;
        },
        hasVideo: function () {
            if(this.review === undefined || this.review.product === undefined){
                return false;
            }
            return this.review.product.video;
        },
        filterdScores() {
            return this.scores.filter(score => score.value !== 0)
        },
        scores: function (){
            if (!this.review || !this.review.spec){
                return [];
            }
            return this.review.spec.specs;
        },
        average_score: function(){
            if (!this.review || !this.review.spec){
                return 0;
            }
            return Math.round(this.review.spec.average);
        },
        video_thumbnail: function (){
            if (!this.review || !this.review.video){
                return "";
            }
            return this.review.video.thumbnail;
        },
        review_links: function (){
            if (!this.review || !this.review.questions){
                return [];
            }
            const dynamic_options = [];
            this.review.questions.forEach(group => {
                if(!isGeneralsubject(group)){
                    dynamic_options.push({
                        text: group.title,
                        to: {
                            name: 'review.show.category',
                            params: {id: this.id, category: encodeURI(group.title)}
                        },
                    });
                }
            });

            return [
                {
                    text: this.t('subject_general'),
                    to: {name: 'review.show.general', params: {id: this.id}},
                },
                ...dynamic_options,
                {
                    text: this.t('all_highlights'),
                    to: {name: 'review.show.highlights', params: {id: this.id}},
                },
            ];
        },
    },
}
</script>

<style  scoped>
    .hr-strategy { 
        background: url('~@/assets/icons/icon_strategy.svg') no-repeat top center;
        background-size: contain;
        display: block;
        height: 30px;
        border: 0;
        position: relative;
    }
    .hr-strategy:before,
    .hr-strategy:after {
        content: '';
        display: block;
        position: absolute;
        @apply bg-gray-800;
        height: 1px;
        top: 14px;
    }
    .hr-strategy:before {
        left: 0;
        right: 50%;
        margin-right: 40px;
    }
    .hr-strategy:after {
        right: 0;
        left: 50%;
        margin-left: 40px;
    }
    .min-h-64 {
        min-height: 16rem;
    }
</style>